import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import router from './router'

const base = axios.create({
  // baseURL: "http://localhost:4000"
  baseURL: "https://stats-api.grayriver.dk"
});


Vue.prototype.$http = base;
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
