<template>
  <div class="recipe-grid">
    <section class="header">
      <h1>
        {{ recipe.name }}
        <small v-if="recipe.url != ''">(<a :href="recipe.url">via</a>)</small>
      </h1>
      {{ recipe.description }}
    </section>
    <section class="meta"></section>

    <section class="ingredients">
      <h2>Ingredients</h2>
      <ul>
        <div v-for="i in recipe.ingredients" :key="i">
          <li v-if="i.indexOf('###') != -1" class="ingredient-group">
            <h3>{{ i.replace("###", "") }}</h3>
          </li>
          <li v-else class="ingredient">{{ i }}</li>
        </div>
      </ul>
    </section>

    <!-- <section class="instructions">
      <h2>Instructions</h2>
      <h5 v-if="recipe.persons">
        {{ recipe.persons }}
      </h5>
      <ol>
        <li v-for="i in recipe.instructions" :key="i" class="instruction">
          {{ i }}
        </li>
      </ol>
    </section> -->

    <section class="instructions">
      <h2>Instructions</h2>
      <h5 v-if="recipe.persons">
        {{ recipe.persons }}
      </h5>
      <div v-for="(section, sectionIndex) in structuredInstructions" :key="sectionIndex">
        <h4 v-if="section.heading">{{ section.heading }}</h4>
        <ol>
          <li v-for="(instruction, instructionIndex) in section.instructions" :key="instructionIndex"
            class="instruction">
            {{ instruction }}
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DetailsView",

  data() {
    return {
      id: "",
      recipe: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getData(this.id);
  },

  methods: {
    async getData(id) {
      let response = await this.$http.get("/recipe/findById?id=" + id);
      this.recipe = response.data;
    },
  },
  computed: {
    instuctions() {
      // let allInstructions = this.recipe.instructions;

      return this.recipe.instructions;
    },
    structuredInstructions() {
      const sections = [];
      let currentSection = { heading: null, instructions: [] };

      this.recipe.instructions.forEach(item => {
        if (item.startsWith('###')) {
          if (currentSection.instructions.length > 0) {
            sections.push(currentSection);
          }
          currentSection = { heading: item.replace('###', '').trim(), instructions: [] };
        } else {
          currentSection.instructions.push(item);
        }
      });

      if (currentSection.instructions.length > 0) {
        sections.push(currentSection);
      }

      return sections;
    }

  },
};
</script>

<style scoped>
@import "../styles/base.css";
@import "../styles/recipe.css";
</style>
