/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Start.vue'
import Recipe from '../views/Details.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/recipe/:id',
    name: 'Recipe',
    component: Recipe,
    // meta: {
    //   requiresAuth: true
    // }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
