<template>
  <div>
    <h1 class="title">Recipes ({{ this.recipes.length }})</h1>
    <div class="post-listing">
      <section
        class="category-listing"
        v-for="(category, key) in recipesByCategory"
        :key="category.name"
      >
        <h1 class="category-title">{{ key }}</h1>
        <ul class="post-list-item" v-for="recipe in category" :key="recipe._id">
          <li>
            <div class="post-title">
              <a class="post-link" :href="'recipe/' + recipe._id">
                {{ recipe.name }}
              </a>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
var _ = require("lodash");

export default {
  name: "StartPage",

  data() {
    return {
      error: false,
      errorMsg: "",
      loading: false,
      search: "",
      recipes: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await this.$http.get("/recipe/all");
      this.recipes = response.data;
    },
  },
  computed: {
    recipesByCategory() {
      return _.groupBy(this.recipes, "category");
    },
  },
};
</script>

<style scoped>
@import "../styles/base.css";
@import "../styles/posts.css";
</style>
